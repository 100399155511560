import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Row } from '../components/helpers';
import ExtraFirmBed from '../../src/Icons/ExtraFirmBed';
import FirmBed from '../../src/Icons/FirmBed';
import MediumBed from '../../src/Icons/MediumBed';
import PlushBed from '../../src/Icons/PlushBed';
import UltraPlushBed from '../../src/Icons/UltraPlushBed';
import ExtraFirm from '../../src/Icons/ExtraFirm';
import Firm from '../../src/Icons/Firm';
import ExtraPlush from '../../src/Icons/ExtraPlush';
import Medium from '../../src/Icons/Medium';
import Plush from '../../src/Icons/Plush';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "icons"
    }}>{`Icons`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Icon from '@mfrm/mfcl/Icons/[Icon Name]'
`}</code></pre>
    <Playground __position={0} __code={'<Row>\n  <ExtraFirmBed width={30} height={30} />\n  <FirmBed width={30} height={30} />\n  <MediumBed width={30} height={30} />\n  <PlushBed width={30} height={30} />\n  <UltraPlushBed width={30} height={30} />\n</Row>\n\n<br />\n<br />\n<Row>\n  <ExtraFirm width={30} height={30} />\n  <ExtraPlush width={30} height={30} />\n  <Firm width={30} height={30} />\n  <Medium width={30} height={30} />\n  <Plush width={30} height={30} />\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Row,
      ExtraFirmBed,
      FirmBed,
      MediumBed,
      PlushBed,
      UltraPlushBed,
      ExtraFirm,
      Firm,
      ExtraPlush,
      Medium,
      Plush,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <ExtraFirmBed width={30} height={30} mdxType="ExtraFirmBed" />
    <FirmBed width={30} height={30} mdxType="FirmBed" />
    <MediumBed width={30} height={30} mdxType="MediumBed" />
    <PlushBed width={30} height={30} mdxType="PlushBed" />
    <UltraPlushBed width={30} height={30} mdxType="UltraPlushBed" />
  </Row>
  
      <br />
      <br />
  <Row mdxType="Row">    
    <ExtraFirm width={30} height={30} mdxType="ExtraFirm" />
    <ExtraPlush width={30} height={30} mdxType="ExtraPlush" />
    <Firm width={30} height={30} mdxType="Firm" />
    <Medium width={30} height={30} mdxType="Medium" />
    <Plush width={30} height={30} mdxType="Plush" />
  </Row>
  
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      